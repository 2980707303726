<template>
  <main>
    <div class="container">
      <Confirmation :mainText="confirmationMainText"
                    :subText="confirmationSubText" />
    </div>
  </main>
</template>

<script>
import Confirmation from "@/components/Confirmation";
import {Utils} from "@/services/utils";

export default {
  name: "ValidateGift",
  components:{ Confirmation},
  props: {
    refGift: {
      type: [String, Number],
      required: true,
    }
  },
  data () {
    return {
      confirmationMainText: `
			Merci,
			<br/> Votre E-carte cadeau n° ${this.refGift ? this.refGift : ''}<br/> est bien enregistrée !
		`,
      confirmationSubText: `
			Un e-mail de confirmation vous a été envoyé.
			<br/>Des questions ?
			<br/>Contactez-nous au <a href="tel:0299894816" target="_blank" style="color: #ae7935; text-decoration: none;">02 99 89 48 16</a>
		`,
    }
  },
}
</script>

<style scoped>
.container {
  @include flex(flex-start, flex-start, column, $gap: 20px);
  position: relative;
  width: 90vw;
  margin: 0 auto;
@media screen and (max-width: 400px) {
  width: 90%;
}
@media screen and (min-width: 992px) {
  width: 90vw;
}
@media screen and (min-width: 1200px) {
  width: 80vw;
}

@media screen and (min-width: 992px) {
  flex-direction: row;
  justify-content: space-between;
}
}
</style>