<template>
	<div class="confirmation">
		<div class="confirmation-box">
			<div class="confirm-svg">
				<template v-if="isError">
					<svg width="64" height="62" viewBox="0 0 64 62" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.88176 61.3705C16.014 54.9001 26.0386 44.4689 35.0116 35.2715C43.3327 26.7401 52.5441 16.8276 55.7969 5.13463C56.9255 1.06871 51.0299 -0.936205 48.6675 1.97303C40.9773 11.4508 35.6005 22.3938 27.3565 31.6332C19.2106 40.7535 8.8916 48.1562 1.34161 57.6971C-0.579187 60.1227 2.47025 62.7655 4.88176 61.3705V61.3705Z" fill="#355878"/>
						<path d="M2.96107 7.63713C9.43149 18.7693 19.8627 28.7939 29.0601 37.767C37.5915 46.0881 47.5039 55.2995 59.1969 58.5522C63.2628 59.6809 65.2678 53.7853 62.3585 51.4229C52.8807 43.7327 41.9378 38.3558 32.6984 30.1118C23.5781 21.966 16.1753 11.647 6.63442 4.09698C4.20889 2.17618 1.56604 5.22562 2.96107 7.63713V7.63713Z" fill="#355878"/>
					</svg>
				</template>
				<template v-else>
					<svg width="63" height="50" viewBox="0 0 63 50" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M60.8388 0C59.3118 1.01804 57.7043 1.88069 56.3327 3.09698C54.6877 4.46865 53.3964 6.26897 51.7515 7.64064C43.3661 14.4186 34.8682 21.0787 26.4828 27.8567C24.0127 29.8553 21.8212 29.1105 19.6298 27.621C17.6687 26.3297 15.9059 24.7222 13.9127 23.5488C10.6978 21.6306 8.86001 21.8664 6.19704 24.5293C4.31635 26.3725 2.67141 28.4836 0.871094 30.5625C1.38011 31.3877 1.69624 32.0521 2.1624 32.5611C7.02218 37.5763 11.957 42.5486 16.8114 47.5638C18.376 49.2087 19.9834 50.4625 22.6892 49.8356C23.9805 48.1907 25.3147 46.2725 26.8418 44.5097C35.6933 34.3614 44.5502 24.2561 53.4822 14.1507C55.8344 11.4877 58.4598 9.09804 60.8067 6.3922C62.2855 4.7044 62.9928 2.74334 60.8388 0ZM21.7784 43.3255C16.7257 38.8622 11.7909 34.5115 6.57746 29.8874C8.10451 28.6336 9.27794 27.696 10.5317 26.6351C13.7037 28.8265 16.5274 31.0608 19.6191 32.8237C24.3181 35.5241 25.6523 35.058 29.2583 31.1787C31.9587 28.3604 34.9378 25.8099 37.799 23.1469C37.9544 23.3827 38.1527 23.656 38.3081 23.8917C32.6338 30.5572 26.9489 37.2173 21.7784 43.3255Z"
							fill="#355878"/>
					</svg>
				</template>
			</div>
			<h2 v-html="mainText"></h2>
			<p v-html="subText"></p>
		</div>

		<div v-if="showButtons" class="confirmation-buttons">
			<router-link v-if="getIsAuthenticated" :to="{name: 'Account'}" class="button full width">
				{{ $t('btn_profile') }}
			</router-link>
			<router-link v-if="!getIsAuthenticated" :to="{name: 'Registration'}" class="button full width">
				{{ $t('connexion') }}
			</router-link>
      <router-link :to="{name: 'Home'}" class="button width">
        Retour à l'Accueil
      </router-link>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "Confirmation",
	computed: {
		...mapGetters('auth', ['getIsAuthenticated']),
	},
	props: {
		mainText: String,
		subText: String,
		showButtons: {
			type: Boolean,
			default: true
		},
		isError: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss" scoped>
.confirmation {
	@include flex(center, center, column, $gap: 20px);
	margin: 0 auto;

	&-box {
		@include flex(center, center, column, $gap: 15px);
		box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);
		border-radius: 10px;
		padding: 25px 50px;
		max-width: 600px;
		text-align: center;
		color: $Black;
		@media screen and (max-width: 767.99px) {
			padding: 15px 20px;
		}

		.confirm-svg {
			@include flex(center, center);
			border: solid 2px $Blue;
			border-radius: 50px;
			padding: 15px;
			aspect-ratio: 1;

			svg {
				@include fixed-size(25px);
				fill: $Blue;
			}
		}

		h2 {
			letter-spacing: 1px;
			color: $Blue;
		}

		p {
			color: $Blue;
			line-height: 1.6;

			a {
				color: $Blue;
			}
		}
	}

	&-buttons {
		@include flex(center, center, column, $gap: 10px);
	}
}
</style>